import React, { useState } from 'react';
import axiosInstance from '../../../services/axiosInstance';
import { Box, Typography, Button, Alert, TextField } from '@mui/material';

const PictureUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        
        // Check file size and type
        const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        
        if (file.size > maxSize) {
            setUploadStatus('File is too large. Please select a file smaller than 10 MB.');
            return;
        }
        
        if (!allowedTypes.includes(file.type)) {
            setUploadStatus('Invalid file type. Please select a PNG or JPEG image.');
            return;
        }
        
        setSelectedFile(file);
        setUploadStatus('');

        // Create a preview URL for the selected image
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!selectedFile || !title) {
            setUploadStatus('Please select a file and provide a title.');
            return;
        }

        const formData = new FormData();
        formData.append('image', selectedFile);
        formData.append('title', title);
        formData.append('description', description);

        try {
            const response = await axiosInstance.post('/pictures/upload/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setUploadStatus('Image uploaded successfully!');
            // Reset form fields
            setSelectedFile(null);
            setPreviewUrl(null);
            setTitle('');
            setDescription('');
        } catch (error) {
            console.error('Upload failed:', error);
            setUploadStatus('Upload failed. Please try again.');
        }
    };

    return (
        <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
            <Typography variant="h4" gutterBottom>
                Upload a Picture
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    multiline
                    rows={3}
                    margin="normal"
                />
                <input
                    accept="image/png,image/jpeg,image/jpg"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    onChange={handleFileChange}
                />
                <label htmlFor="raised-button-file">
                    <Button variant="contained" component="span">
                        Choose File
                    </Button>
                </label>
                {selectedFile && (
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        Selected file: {selectedFile.name}
                    </Typography>
                )}
                {previewUrl && (
                    <Box sx={{ mt: 2, mb: 2 }}>
                        <img
                            src={previewUrl}
                            alt="Preview"
                            style={{ maxWidth: '100%', maxHeight: 200 }}
                        />
                    </Box>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{ mt: 2 }}
                    disabled={!selectedFile}
                >
                    Upload
                </Button>
            </form>
            {uploadStatus && (
                <Alert severity={uploadStatus.includes('successfully') ? 'success' : 'error'} sx={{ mt: 2 }}>
                    {uploadStatus}
                </Alert>
            )}
        </Box>
    );
};

export default PictureUpload;