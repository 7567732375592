// BarChartCard.js
import React from 'react';
import { Card, CardHeader, CardContent, Typography } from '@mui/material';
import BarChartHorizontal from '../charts/BarChartHorizontal';

const BigBarChartHorizontalCard = ({ title, labels, data, hoch }) => {
    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '0px',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(10px)',
            }}
        >
            <CardHeader
                title={
                    <Typography variant="h6" component="div">
                        {title}
                    </Typography>
                }
            />
            <CardContent>
                <BarChartHorizontal
                    labels={labels}
                    data={data}
                    hoch = {hoch}
                />
            </CardContent>
        </Card>
    );
};

export default BigBarChartHorizontalCard;
