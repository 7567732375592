import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../services/axiosInstance';
import { Box, Typography, CircularProgress, Grid, TextField, Modal } from '@mui/material';

const PictureView = () => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axiosInstance.get('/pictures/');
                // Extract the results array from the response
                setImages(response.data.results || []);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching images:', err);
                setError('Failed to load images. Please try again later.');
                setLoading(false);
            }
        };

        fetchImages();
    }, []);

    const filteredImages = images.filter(image =>
        image.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ maxWidth: 1200, margin: 'auto', padding: 2 }}>
            <Typography variant="h4" gutterBottom>
                Picture Gallery
            </Typography>
            <TextField
                fullWidth
                label="Search pictures"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ mb: 3 }}
            />
            <Grid container spacing={3}>
                {filteredImages.map((image) => (
                    <Grid item xs={12} sm={6} md={4} key={image.id}>
                        <ImageCard image={image} onClick={() => handleImageClick(image)} />
                    </Grid>
                ))}
            </Grid>
            <FullImageModal image={selectedImage} onClose={handleCloseModal} />
        </Box>
    );
};

const ImageCard = ({ image, onClick }) => {
    return (
        <Box 
            sx={{ 
                border: '1px solid #ddd', 
                borderRadius: 2, 
                overflow: 'hidden',
                cursor: 'pointer',
                '&:hover': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                },
            }}
            onClick={onClick}
        >
            <img 
                src={image.image}
                alt={image.title}
                style={{ width: '100%', height: 200, objectFit: 'cover' }}
            />
            <Box sx={{ p: 2 }}>
                <Typography variant="h6">{image.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                    {image.description}
                </Typography>
                <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                    Uploaded: {new Date(image.uploaded_at).toLocaleDateString()}
                </Typography>
            </Box>
        </Box>
    );
};

const FullImageModal = ({ image, onClose }) => {
    if (!image) return null;

    return (
        <Modal
            open={!!image}
            onClose={onClose}
            aria-labelledby="full-image-modal"
            aria-describedby="full-size-image"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                maxWidth: '90vw',
                maxHeight: '90vh',
                overflow: 'auto',
            }}>
                <img 
                    src={image.image}
                    alt={image.title}
                    style={{ width: '100%', height: 'auto', maxHeight: '80vh', objectFit: 'contain' }}
                />
                <Typography variant="h6" sx={{ mt: 2 }}>{image.title}</Typography>
                <Typography variant="body2">{image.description}</Typography>
            </Box>
        </Modal>
    );
};

export default PictureView;
