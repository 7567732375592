import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Grid, Typography, Card, CardHeader, CardContent,Tooltip  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VariableTable from "../../table/VariableTable";  // Importiere die VariableTable-Komponente
import BarChartTA from "../../charts/BarChartTA";
import ErrorPopup from "../../utility/ErrorPopup";
import axiosInstance from "../../../services/axiosInstance";
import { formatPercentage, roundToDecimals, tausenderTrennung } from "../../../utils/mathStuff";
import dayjs from "dayjs";
import { FilterContext } from "../../../provider/FilterProvider";
import GroupedBarChart from "../../charts/GroupedBarChart";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const TAPerformanceUmsatz = ({ data, open, setOpen, zeitraum, scoreValue, rangValue, percentageValue }) => {
    const { filterState } = useContext(FilterContext);
    const [openError, setOpenError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [scoreBarChartData, setScoreBarChartData] = useState([]);
    const [groupedBarChartData, setGroupedBarChartData] = useState([]);

    useEffect(() => {
        if (filterState.filiale === null || filterState.datum === null) {
            setOpenError(true);
        }
    }, [filterState.filiale, filterState.datum]);

    useEffect(() => {
        if (open && filterState.filiale && filterState.datum) {
            setLoading(true);
            fetchTableData();
            fetchBarChartData();
        }
    }, [filterState.filiale, filterState.datum, open]);

    const formatDate = (dateString) => {
        return dayjs(dateString).format('dddd, D. MMMM YYYY');
    };

    const handleCloseError = () => {
        setOpenError(false);
        setOpen(false); // Close the main dialog as well
    };

    if (openError) {
        return (
            <ErrorPopup
                open={openError}
                handleClose={handleCloseError}
                message={"Bitte wählen sie eine Filiale und ein Datum aus"}
            />
        );
    }

    const filialname = filterState.filiale ? filterState.filiale.label : 'Filiale';
    const datum = filterState.datum ? filterState.datum.label : 'Datum';

    const handleClose = () => {
        setOpen(false);
    };

    const fetchTableData = async () => {
        try {
            const response = await axiosInstance.get('/fact/kundenstatistik/', {
                params: {
                    filiale: filterState.filiale?.value,
                    jahrMonat: filterState.datum?.value,
                }
            });
            if (Array.isArray(response.data.results)) {
                const mappedData = response.data.results.map((item) => {
                    return {
                        datum: formatDate(item.datum.split(' ')[0]),
                        umsatz: tausenderTrennung(item.umsatz, 2),
                        kunden: item.kundenanzahl,
                        kundenbon: tausenderTrennung(item.umsatz / item.kundenanzahl, 2),
                    };
                });
                setTableData(mappedData);
            } else {
                console.error('No data received from the backend');
            }
        } catch {
            console.error('Error while fetching data');
        } finally {
            setLoading(false);
        }
    };

    const fetchBarChartData = async () => {
        try {
            const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(18, 'month');

            const response = await axiosInstance.get('scorecards_month/performance-kennzahl/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filterState.filiale?.value
                }
            });

            const dataScoreBarChart = response.data.results
                .slice(-6)
                .map(item => ({
                    x: item.datum,
                    score: item[comparisonCategoryScores]
                }));

            setScoreBarChartData(dataScoreBarChart);

            const last18MonthsData = response.data.results;

            const currentSixMonthsData = last18MonthsData.slice(-6).map(item => ({
                x: dayjs(item.datum).format('MMM'), // Format the month name for display
                currentScore: item[comparisonCategory] || 0,
                previousScore: 0 // Placeholder, to be filled later
            }));

            const previousSixMonthsData = last18MonthsData.slice(0, 6).map((item, index) => ({
                x: dayjs(item.datum).format('MMM'), // This should match with currentSixMonthsData.x
                previousScore: item[comparisonCategory] || 0
            }));

            const combinedData = currentSixMonthsData.map((currentData, index) => {
                const previousData = previousSixMonthsData[index];
                return {
                    x: currentData.x,
                    currentScore: currentData.currentScore,
                    previousScore: previousData ? previousData.previousScore : 0
                };
            });
            setGroupedBarChartData(combinedData);
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    };

    const columns = [
        { accessorKey: 'datum', header: 'Datum' },
        { accessorKey: 'umsatz', header: 'Umsatz €' },
        { accessorKey: 'kunden', header: 'Kunden' },
        { accessorKey: 'kundenbon', header: 'Kundenbon €' },
    ];

    const comparisonCategoryGesamt = zeitraum === 'Vormonat' ? 'gesamtUmsatzWachstumM' :
        zeitraum === 'Vorjahr' ? 'gesamtUmsatzWachstumVJ' :
            zeitraum === '∅ 6. Monate' ? 'gesamtUmsatzWachstum6M' : '';

    const comparisonCategory = zeitraum === 'Vormonat' ? 'umsatzVM' :
        zeitraum === 'Vorjahr' ? 'umsatzVJ' :
            zeitraum === '∅ 6. Monate' ? 'umsatz6M' : '';

    const comparisonCategoryScores = zeitraum === 'Vormonat' ? 'scoreUmsatzWachstumM' :
        zeitraum === 'Vorjahr' ? 'scoreUmsatzWachstumJ' :
            zeitraum === '∅ 6. Monate' ? 'scoreUmsatzWachstum6M' : '';

    const gesamtUmsatzWachstum = data.length > 0 ? formatPercentage(data[1][comparisonCategoryGesamt]) : 'N/A';

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>
                Tiefenanalyse Umsatz
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                padding: '0px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                            }}>
                            <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
                                        Verlauf des Scores in den letzen 6 Monaten
                                    </Typography>
                                }
                                action={
                                    <Tooltip title="Dies zeigt den Verlauf des Umsatz-Scores über die letzten Monate.">
                                        <IconButton>
                                            <InfoOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <div style={{ height: '100px' }}>
                                    <BarChartTA data={scoreBarChartData} category={"score"} flat={false} xAxis={true} />
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                padding: '0px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                            }}>
                            <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
                                        Zusammenfassung
                                    </Typography>
                                }
                                
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <div>
                                    <Typography variant="body1">
                                        {`Im Vergleich zum ${zeitraum} beträgt die Umsatzentwicklung in ${filialname} im Monat ${datum} ${percentageValue}%.`}
                                    </Typography>
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        {`Das Gesamtunternehmen entwickelte sich im Vergleichszeitraum um ${gesamtUmsatzWachstum}%. Mit diesem Ergebnis landet die Filiale auf dem
                                ${rangValue} Rang, hieraus resultiert ein Score von ${scoreValue} von insgesamt 10 möglichen Punkten.`}
                                    </Typography>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                padding: '0px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                            }}>
                            <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
                                        Vergleich des Umsatzes zum Vorjahr
                                    </Typography>
                                }
                                action={
                                    <Tooltip title="Dieser Vergleich zeigt die Umsätze der letzten 6 Monate im Vergleich zum gleichen Zeitraum im Vorjahr.">
                                        <IconButton>
                                            <InfoOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <div style={{ height: '200px' }}>
                                    <GroupedBarChart data={groupedBarChartData} />
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                padding: '0px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                            }}>
                            <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
                                        Tagesübersicht
                                    </Typography>
                                }
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                {loading ? <div>Loading...</div> :
                                    <VariableTable
                                        columns={columns}
                                        data={tableData}
                                    />}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default TAPerformanceUmsatz;
