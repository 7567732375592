// Pfad: pages/your-page-path/Timetable.jsx

import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import Layout from '../../../components/layout/Tagesreport/Tagesreport';
import {
  Box, Card, Typography, useTheme, Button, IconButton, Dialog, DialogTitle,
  DialogContent, TextField, DialogActions, List, ListItem, Checkbox
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import axios from '../../../services/axiosInstance';
import TagesplanerBackhinweis from '../../../components/popup/TagesplanerBackhinweis';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import { FilterContext } from "../../../provider/FilterProvider";
import { useAuth } from "../../../provider/authProvider";

// Import für Recharts
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Dot } from 'recharts';

const Timetable = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentHour, setCurrentHour] = useState(dayjs().hour());
  const [showAllHours, setShowAllHours] = useState(false);
  const [todos, setTodos] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState(null);
  const [newTodo, setNewTodo] = useState('');
  const [nameDialogOpen, setNameDialogOpen] = useState(false);
  const [name, setName] = useState('');
  const timetableRef = useRef(null);
  const [articleDialogOpen, setArticleDialogOpen] = useState(false);
  const [articleDialogHour, setArticleDialogHour] = useState(null);
  const [lastOpenedHour, setLastOpenedHour] = useState(null);
  const [selectedFiliale, setSelectedFiliale] = useState('');
  const [filialeOptions, setFilialeOptions] = useState([]);
  const [tagesplanerData, setTagesplanerData] = useState([]);
  const [chartDialogOpen, setChartDialogOpen] = useState(false); // Neuer State für das Chart-Popup

  const { filterState, handleFilialeChange } = useContext(FilterContext);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentHour(dayjs().hour());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timetableRef.current) {
      const currentHourElement = timetableRef.current.querySelector(`#hour-${currentHour}`);
      if (currentHourElement) {
        currentHourElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [currentHour]);

  useEffect(() => {
    const fetchFilialeOptions = async () => {
      try {
        const response = await axios.get('stammdaten/unique-filiale/');
        let options = response.data.map((item) => ({
          label: item.filiale,
          value: item.filiale,
        }));

        const username = user.username;
        if (!isNaN(username)) {
          options = options.filter((option) =>
            option.label.includes(username)
          );
        }
        handleFilialeChange(options[0]);
        setFilialeOptions(options);
      } catch (error) {
        console.error('Fehler beim Laden der Filialen:', error);
      }
    };
    fetchFilialeOptions();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentHour = dayjs().hour();
      const allowedHours = [7, 9, 11, 13, 15, 17];

      if (allowedHours.includes(currentHour) && currentHour !== lastOpenedHour) {
        setArticleDialogHour(currentHour);
        setArticleDialogOpen(true);
        setLastOpenedHour(currentHour);
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [lastOpenedHour]);

  // Aktualisierter useEffect-Hook zum Abrufen der Tagesplaner-Daten
  useEffect(() => {
    const fetchTagesplanerData = async () => {
      try {
        const currentDate = dayjs().format('YYYY-MM-DD');
        const filialnummer = filterState.filiale?.label;

        if (filialnummer) {
          const response = await axios.get('tagesplaner/tagesplaner-summary/', {
            params: {
              filialbezeichnung: filialnummer,
              datum: currentDate,
            },
          });
          console.log('API Response:', response.data);

          // Extrahieren des 'results'-Arrays
          let data = response.data.results || [];

          setTagesplanerData(data);
        }
      } catch (error) {
        console.error('Fehler beim Laden der Tagesplaner-Daten:', error);
        setTagesplanerData([]);
      }
    };

    fetchTagesplanerData();
  }, [filterState.filiale]);

  // Daten nach Stunden strukturieren
  const tagesplanerByHour = useMemo(() => {
    const mapping = {};
    if (Array.isArray(tagesplanerData)) {
      tagesplanerData.forEach((item) => {
        if (item && typeof item.uhrzeit === 'string') {
          const hour = parseInt(item.uhrzeit.split(':')[0], 10);
          mapping[hour] = item;
        } else {
          console.warn('Item mit undefinierter uhrzeit:', item);
        }
      });
    }
    console.log('tagesplanerByHour keys:', Object.keys(mapping));
    return mapping;
  }, [tagesplanerData]);

  const hours = Array.from({ length: 18 }, (_, index) => index + 4); // Stunden von 4 bis 21
  const visibleHours = showAllHours ? hours : hours.filter((hour) => hour >= currentHour);

  const handleOpenDialog = (hour) => {
    setSelectedHour(hour);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setNewTodo('');
  };

  const handleSaveTodo = () => {
    setTodos((prevTodos) => ({
      ...prevTodos,
      [selectedHour]: [
        ...(prevTodos[selectedHour] || []),
        { text: newTodo, completed: false, completedBy: '' },
      ],
    }));
    handleCloseDialog();
  };

  const handleOpenArticleDialog = (hour) => {
    setArticleDialogHour(hour);
    setArticleDialogOpen(true);
  };

  const handleCloseArticleDialog = () => {
    setArticleDialogOpen(false);
    setArticleDialogHour(null);
  };

  const handleToggleTodo = (hour, index) => {
    setTodos((prevTodos) => {
      const updatedTodos = { ...prevTodos };
      const todo = { ...updatedTodos[hour][index] };
      todo.completed = !todo.completed;
      updatedTodos[hour][index] = todo;
      return updatedTodos;
    });
  };

  // Funktion zum Öffnen des Chart-Popups
  const handleOpenChartDialog = () => {
    setChartDialogOpen(true);
  };

  const handleCloseChartDialog = () => {
    setChartDialogOpen(false);
  };

  // Daten für das Chart vorbereiten
  const chartData = useMemo(() => {
    if (!Array.isArray(tagesplanerData)) return [];
    return tagesplanerData.map((item) => ({
      uhrzeit: item.uhrzeit.substring(0, 5), // Uhrzeit im Format 'HH:mm'
      kundenanzahl: item.kundenanzahl,
      scoreTagesplaner: item.scoreTagesplaner,
    }));
  }, [tagesplanerData]);

  // Funktion zum Bestimmen der Punktfarbe basierend auf scoreTagesplaner
  const getPointColor = (score) => {
    if (score >= 1 && score < 4) {
      return theme.palette.success.main; // Rot
    } else if (score >= 4 && score < 7) {
      return theme.palette.warning.main; // Gelb
    } else if (score >= 7) {
      return theme.palette.error.main; // Grün
    } else {
      return theme.palette.grey[500]; // Standardfarbe
    }
  };

  // Angepasstes Dot-Rendering für die Linie
  const CustomizedDot = (props) => {
    const { cx, cy, payload } = props;
    const color = getPointColor(payload.scoreTagesplaner);
    return (
      <Dot cx={cx} cy={cy} r={5} fill={color} />
    );
  };

  return (
    <Layout>
      <PageHeader
        title="Tagesplaner"
        subtitle="Übersicht des aktuellen Tags"
        Icon={BarChartIcon}
      />
      <Box sx={{ marginTop: 2, padding: 2, maxHeight: '80vh', overflowY: 'auto' }} ref={timetableRef}>
        <DropdownFilter
          label="Filiale"
          options={filialeOptions}
          value={filterState.filiale}
          onChange={handleFilialeChange}
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
          <Box>
            {!showAllHours && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowAllHours(true)}
                sx={{ marginRight: 1 }}
              >
                Alle Stunden anzeigen
              </Button>
            )}
            {showAllHours && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setShowAllHours(false)}
                sx={{ marginRight: 1 }}
              >
                Stunden ausblenden
              </Button>
            )}
            {/* Neuer Button zum Öffnen des Chart-Popups */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenChartDialog}
            >
              Kundenverlauf anzeigen
            </Button>
          </Box>
        </Box>

        {visibleHours.map((hour) => {
          const dataForHour = tagesplanerByHour[hour];
          let borderColor = 'transparent';

          if (dataForHour) {
            const score = dataForHour.scoreTagesplaner;
            if (score >= 1 && score < 4) {
              borderColor = theme.palette.error.main; // Rot
            } else if (score >= 4 && score < 7) {
              borderColor = theme.palette.warning.main; // Gelb
            } else if (score >= 7) {
              borderColor = theme.palette.success.main; // Grün
            }
          }

          return (
            <Card
              key={hour}
              id={`hour-${hour}`}
              sx={{
                marginBottom: 2,
                padding: 2,
                position: 'relative',
                backgroundColor:
                  currentHour === hour ? theme.palette.primary.light : theme.palette.background.paper,
                border: `2px solid ${borderColor}`,
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">{`${hour}:00 - ${hour + 1}:00`}</Typography>
                <Box>
                  <IconButton
                    onClick={() => handleOpenDialog(hour)}
                    color="primary"
                    aria-label="add todo"
                  >
                    <AddIcon />
                  </IconButton>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenArticleDialog(hour)}
                  >
                    Artikel anzeigen
                  </Button>
                </Box>
              </Box>

              {/* Anzeige der Kundenanzahl */}
              {dataForHour && (
                <Typography variant="body1" sx={{ marginTop: 1 }}>
                  Kundenanzahl: {dataForHour.kundenanzahl}
                </Typography>
              )}

              {/* Anzeige der To-Dos */}
              {todos[hour] && (
                <List>
                  {todos[hour].map((todo, index) => (
                    <ListItem key={index} sx={{ paddingLeft: 0 }}>
                      <Checkbox
                        checked={todo.completed}
                        onChange={() => handleToggleTodo(hour, index)}
                        color="primary"
                      />
                      <Typography
                        sx={{
                          textDecoration: todo.completed ? 'line-through' : 'none',
                          color: todo.completed ? theme.palette.text.secondary : 'inherit',
                        }}
                      >
                        {todo.text}
                      </Typography>
                      {todo.completed && (
                        <Typography variant="caption" sx={{ marginLeft: 1 }}>
                          (Abgehakt von: {todo.completedBy})
                        </Typography>
                      )}
                    </ListItem>
                  ))}
                </List>
              )}
            </Card>
          );
        })}
      </Box>

      {/* Dialog zum Hinzufügen eines neuen To-Dos */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Neues To-Do für {selectedHour}:00 hinzufügen</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="To-Do"
            type="text"
            fullWidth
            variant="standard"
            value={newTodo}
            onChange={(e) => setNewTodo(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Abbrechen
          </Button>
          <Button onClick={handleSaveTodo} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog zum Anzeigen der Artikel */}
      <TagesplanerBackhinweis
        open={articleDialogOpen}
        onClose={handleCloseArticleDialog}
        hour={articleDialogHour}
        filiale={filterState.filiale?.label}
      />

      {/* Dialog zum Anzeigen des Liniendiagramms */}
      <Dialog open={chartDialogOpen} onClose={handleCloseChartDialog} maxWidth="md" fullWidth>
        <DialogTitle>Kundenverlauf</DialogTitle>
        <DialogContent>
          <LineChart
            width={600}
            height={400}
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="uhrzeit" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="kundenanzahl"
              stroke={theme.palette.primary.main}
              dot={<CustomizedDot />}
            />
          </LineChart>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseChartDialog} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default Timetable;
