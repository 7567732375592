// Fehlmenge.js
import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layout/Tagesreport/Tagesreport';
import { Box, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import VerkaufsgebietFilterSelection from '../../../components/tagesreport/VerkaufsgebietFilterSelection';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import axiosInstance from '../../../services/axiosInstance';
import { roundToDecimals } from '../../../utils/mathStuff';
import BigTableCard from '../../../components/card/BigTableCard';

const Fehlmenge = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [datum, setDatum] = useState(dayjs().subtract(1, 'day').format('YYYY-MM-DD'));
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [verkaufsgebietOptions, setVerkaufsgebietOptions] = useState([]);
  const [verkaufsgebietFilter, setVerkaufsgebietFilter] = useState(null);
  const date = new Date(datum);
  const dayName = date.toLocaleDateString('de-DE', { weekday: 'long' });
  const [totals, setTotals] = useState({
    Liefermenge: 0,
    Korrektur: 0,
    Verkaufsmenge: 0,
    Retourmenge: 0,
    Fehlmenge: 0,
  });

  useEffect(() => {
    fetchVerkaufsgebietOptions();
  }, []);

  useEffect(() => {
    fetchData();
  }, [datum, verkaufsgebietFilter]);

  const fetchVerkaufsgebietOptions = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/unique-verkaufsgebiet/');
      setVerkaufsgebietOptions(response.data.map((item) => ({ label: item, value: item })));
    } catch (error) {
      console.error('Error while fetching VerkaufsgebietOptions', error);
    }
  };

  const fetchData = async () => {
    const formattedDatum = dayjs(datum).format('YYYY-MM-DD');

    try {
      setLoading(true);
      const response = await axiosInstance.get('/fact/facts/', {
        params: {
          startdatum: formattedDatum,
          enddatum: formattedDatum,
          mindestLiefermenge: 1,
          warengruppe: 2,
          verkaufsgebiet: verkaufsgebietFilter?.value,
        },
      });

      if (Array.isArray(response.data.results)) {
        const mappedData = response.data.results
          .map((item) => ({
            Filiale: item.filiale,
            Artikel: item.artikel,
            Liefermenge: item.liefermenge || 0,
            Korrektur: item.korrekturmenge || 0,
            Verkaufsmenge: item.verkaufsmenge || 0,
            Retourmenge: item.retourmenge || 0,
            Fehlmenge:
              item.liefermenge -
              item.verkaufsmenge -
              item.retourmenge +
              item.korrekturmenge ||
              0,
          }))
          .filter((item) => item.Fehlmenge !== 0);

        setData(mappedData);
        setTotals({
          Liefermenge: response.data.totals.total_liefermenge || 0,
          Korrektur: roundToDecimals(response.data.totals.total_korrektur, 2) || 0,
          Verkaufsmenge: roundToDecimals(response.data.totals.total_verkaufsmenge, 2) || 0,
          Retourmenge: roundToDecimals(response.data.totals.total_retourmenge, 2) || 0,
          Fehlmenge: roundToDecimals(response.data.totals.total_fehlmenge, 2) || 0,
        });
      } else {
        console.error('Response data is not an array', response.data);
      }
    } catch (error) {
      console.error('Error while fetching Tagesreport Fehlmenge', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { accessorKey: 'Filiale', header: 'Filiale', footer: 'Gesamt', size: 200 },
    { accessorKey: 'Artikel', header: 'Artikel', footer: '', size: 200 },
    { accessorKey: 'Liefermenge', header: 'Liefermenge', footer: totals.Liefermenge, size: 170 },
    { accessorKey: 'Korrektur', header: 'Korrektur', footer: totals.Korrektur, size: 140 },
    { accessorKey: 'Verkaufsmenge', header: 'Verkaufsmenge', footer: totals.Verkaufsmenge, size: 150 },
    { accessorKey: 'Retourmenge', header: 'Retourmenge', footer: totals.Retourmenge, size: 150 },
    { accessorKey: 'Fehlmenge', header: 'Fehlmenge', footer: totals.Fehlmenge, size: 150 },
  ];

  return (
    <Layout>
      <Container
        maxWidth={false}
        sx={{
          padding: '0 16px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          marginTop: isMobile ? theme.spacing(8) : 0,
        }}
      >
        <PageHeader
          title={`Fehlmenge vom ${dayName} ${datum}`}
          subtitle="Übersicht über die Fehlmengen für den letzten Tag."
          Icon={BarChartIcon}
        />
        <br />
        <VerkaufsgebietFilterSelection
          verkaufsgebietFilter={verkaufsgebietFilter}
          setVerkaufsgebietFilter={setVerkaufsgebietFilter}
          verkaufsgebietOptions={verkaufsgebietOptions}
        />
        <br />
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress size={80} />
          </div>
        ) : (
          <Box sx={{ flexGrow: 1, width: '100%' }}>
            <BigTableCard columns={columns} data={data} sx={{ width: '100%' }} />
          </Box>
        )}
      </Container>
    </Layout>
  );
};

export default Fehlmenge;
