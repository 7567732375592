import ProtectedRoute from "./ProtectedRoute";

const importAll = (r, prefix) => r.keys().map((key) => ({key, module: r(key).default, prefix}));

// Import all components from specific subfolders
// importAll and require.context are webpack specific functions
const scorecardsComponents = importAll(require.context('../pages/scorecards', true, /\.jsx$/), 'scorecards');
const unternehmenComponents = importAll(require.context('../pages/unternehmen', true, /\.jsx$/), 'unternehmen');
const warensteuerungComponents = importAll(require.context('../pages/warensteuerung', true, /\.jsx$/), 'warensteuerung');
const filialsteuerungComponents = importAll(require.context('../pages/filialsteuerung', true, /\.jsx$/), 'filialsteuerung');
const dokumentationComponents = importAll(require.context('../pages/dokumentation', true, /\.jsx$/), 'dokumentation');
const tagesreportComponents = importAll(require.context('../pages/tagesreport', true, /\.jsx$/), 'tagesreport');
const filialcheckComponents = importAll(require.context('../pages/filialcheck', true, /\.jsx$/), 'filialcheck');
const tagesplannerComponents = importAll(require.context('../pages/tagesplanner', true, /\.jsx$/), 'tagesplanner');
const projektComponents = importAll(require.context('../pages/projekt', true, /\.jsx$/), 'projekt');
const sortimentComponents = importAll(require.context('../pages/sortiment', true, /\.jsx$/), 'sortiment');
const controllingComponents = importAll(require.context('../pages/controlling', true, /\.jsx$/), 'controlling');
const onboardingComponents = importAll(require.context('../pages/onboarding', true, /\.jsx$/), 'onboarding');
const settingsComponents = importAll(require.context('../pages/settings', true, /\.jsx$/), 'settings');

// Combine all imported components
const components = [
    ...scorecardsComponents,
    ...unternehmenComponents,
    ...warensteuerungComponents,
    ...filialsteuerungComponents,
    ...dokumentationComponents,
    ...tagesreportComponents,
    ...filialcheckComponents,
    ...tagesplannerComponents,
    ...projektComponents,
    ...sortimentComponents,
    ...controllingComponents,
    ...onboardingComponents,
    ...settingsComponents,
];

const generateRoutes = (components) => {
    return components.map(({key, module: Component, prefix}) => {
        const path = key
            .replace('../pages', '') // Remove the base path
            .replace(/^\./, '')
            .replace(/\.jsx$/, '') // Remove the file extension
            .replace(/\[([^\]]+)]/g, ':$1') // Handle dynamic segments
            .replace(/([^\/])([A-Z])/g, '$1-$2')
            .toLowerCase(); // Convert to lowercase

        // if prefix provide, add it. e.g. /prefix/...
        const fullPath = prefix ? `/${prefix}${path}` : path;

        // Wrap the component in a ProtectedRoute module for permission is the prefix
        const protectedComponent = () => {
            return (
                <ProtectedRoute module={prefix}>
                    <Component/>
                </ProtectedRoute>
            );
        }

        return {
            path: fullPath,
            component: protectedComponent,
        }
    });
};

// Generate and export routes
export const routes = generateRoutes(components);
