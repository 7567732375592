import {useAuth} from "../../provider/authProvider";

const Experimental = () => {
    const {permissions} = useAuth();
    console.log("inside permissions", permissions)
    return (
        <div>
            <h1>Experimental page</h1>

        </div>
    );
};

export default Experimental;
