import {Button, Box, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import React from "react";

const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', pt: '0vh' }}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h1" color="error.main" underline>404 - Not Found</Typography>
                <Box sx={{ my: 2 }}>
                    <Typography variant="h5">The page you are looking for does not exist</Typography>
                    <Typography variant="h5">Die Seite nach der du gesucht hast gibt es nicht</Typography>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ fontSize: '1.25rem', padding: '10px 20px', minWidth: '150px' }}
                    onClick={() => navigate('/')}
                >
                    Back Home
                </Button>
            </Box>
        </Box>
    )
}


export default NotFoundPage;
