import axiosInstance from "./axiosInstance";

export const validateToken = async (token) => {
    try {
        const response = await axiosInstance.post('/auth/verify/', {token});
        return response.status === 200;
    } catch (error) {
        console.error('Token validation failed: ', error.message);
        return false;
    }
};

export const validatePermission = async (module, submodule) => {
    try {
        const response = await axiosInstance.get('/auth/check-permission/', {params: {module, submodule}});
        return response.data.has_permission;
    } catch (error) {
        console.error('Permission validation failed: ', error.message);
        return false;
    }
};
