import React, { useEffect, useState, useRef } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme } from '@mui/material/styles';
import useResizeObserver from 'use-resize-observer';

const BarChartHorizontal = ({ title, labels, data, hoch, }) => {
    const theme = useTheme();
    const [leftMargin, setLeftMargin] = useState(100); // Default margin for labels
    const chartContainerRef = useRef(); // Ref for the chart container
    const { width = 1, height = hoch } = useResizeObserver({ ref: chartContainerRef });
    const [fontSize, setFontSize] = useState(14); // Default font size for labels

    useEffect(() => {
        // Temporary element to measure the label width
        const tempDiv = document.createElement('div');
        document.body.appendChild(tempDiv);
        tempDiv.style.position = 'absolute';
        tempDiv.style.visibility = 'hidden';
        tempDiv.style.whiteSpace = 'nowrap';
        tempDiv.style.fontSize = `${fontSize}px`; // Use dynamic font size for the labels

        // Calculate the maximum width of the labels
        let maxWidth = 0;
        labels.forEach(label => {
            tempDiv.textContent = label;
            maxWidth = Math.max(maxWidth, tempDiv.offsetWidth);
        });

        document.body.removeChild(tempDiv);

        // Adjust the left margin based on the maximum label width, adding a 10px buffer
        setLeftMargin(maxWidth + 10);
    }, [labels, fontSize]);

    useEffect(() => {
        // Dynamically adjust font size based on the container width
        if (width < 400) {
            setFontSize(10);
        } else if (width < 800) {
            setFontSize(12);
        } else {
            setFontSize(14);
        }
    }, [width]);

    return (
        <div ref={chartContainerRef} style={{ width: '100%', height: '100%' }}>
            <BarChart
                xAxis={[
                    {
                        scaleType: 'linear',
                        show: true,
                        label: null,
                    },
                ]}
                yAxis={[
                    {
                        data: labels,
                        scaleType: 'band',
                        show: true,
                        label: null,
                        style: { fontSize: `${fontSize}px`,color: '#FFFFFF' }, // Dynamic font size
                    },
                ]}
                series={[
                    {
                        data: data,
                        color: theme.palette.primary.main,
                    },
                ]}
                barLabel="value"
                layout="horizontal"
                height={hoch? hoch:500 } // Dynamically set by container height
                width={width}    // Dynamically set by container width
                margin={{ top: 10, bottom: 20, left: leftMargin, right: 10 }} // Dynamic left margin
                isAnimationActive={false}
            />
        </div>
    );
};

export default BarChartHorizontal;
