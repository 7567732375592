import React, {useEffect, useState} from 'react';
import {Box, Button, Typography} from '@mui/material';
import axiosInstance from "../../../services/axiosInstance";
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import dayjs from "dayjs";
import Layout from '../../../components/layout/Sortiment/Sortiment';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import ThekeHeader from "../../../components/header/ThekeHeader";
import ThekeGroup from "../../../components/theke/ThekeGroup";
import ArtikelListe from "../../../components/theke/ArtikelListe";
import ThekeSettingsPopup from "../../../components/popup/ThekeSettingsPopup";
import ThekeOverview from "../../../components/theke/ThekeOverview";
import {useLocation} from 'react-router-dom';

const ThekeNewPage = () => {
    const [warengruppen, setWarengruppen] = useState([]);
    const [artikel, setArtikel] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState(null);
    const [datum, setDatum] = useState('2024-01');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filiale, setFiliale] = useState('7010');
    const [selectedArtikel, setSelectedArtikel] = useState([]);
    const [showOverview, setShowOverview] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [sectionCounts, setSectionCounts] = useState({});
    const [savingData, setSavingData] = useState(false)

    const location = useLocation();


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const start = params.get('startDate');
        const end = params.get('endDate');
        if (start && end) {
            setStartDate(dayjs(start));
            setEndDate(dayjs(end));
        }
    }, [location.search]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const endDate = dayjs(`${datum}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(1, 'month');

            const [warengruppenResponse, artikelResponse] = await Promise.all([
                axiosInstance.get('/stammdaten/stammdatenwarengruppe/'),
                axiosInstance.get('/fact/facts/', {
                    params: {
                        startdatum: startDate.format('YYYY-MM-DD'),
                        enddatum: endDate.format('YYYY-MM-DD'),
                        filiale: filiale,
                    },
                }),
            ]);

            const warengruppenData = warengruppenResponse.data.results;
            const artikelData = artikelResponse.data.results || artikelResponse.data;
            console.log("artikelData", artikelData)
            console.log("artikelResponse", artikelResponse)

            if (Array.isArray(warengruppenData)) {
                setWarengruppen(warengruppenData.filter(warengruppe => warengruppe.warengruppennummer < 7));
            } else {
                console.error('Unerwartetes Antwortformat für Warengruppen:', warengruppenResponse.data);
                setWarengruppen([]);
            }

            if (Array.isArray(artikelData)) {
                const filteredArtikel = artikelData
                    .filter(artikel => artikel.warengruppennummer < 7)
                    .reduce((acc, artikel) => {
                        const existingArtikel = acc.find(a => a.artikelnummer === artikel.artikelnummer);
                        if (existingArtikel) {
                            existingArtikel.umsatz += artikel.umsatz;
                        } else {
                            acc.push({
                                artikelnummer: artikel.artikelnummer,
                                artikelbezeichnung: artikel.artikel,
                                umsatz: artikel.umsatz,
                                warengruppennummer: artikel.warengruppennummer,
                            });
                        }
                        return acc;
                    }, []);
                console.log("filteredArtikel", filteredArtikel)
                setArtikel(filteredArtikel.map(artikel => ({...artikel, umsatz: Math.round(artikel.umsatz)})));
            } else {
                console.error('Unerwartetes Antwortformat für Artikel:', artikelResponse.data);
                setArtikel([]);
            }
        } catch (error) {
            console.error('Fehler beim Laden der Daten:', error);
            setWarengruppen([]);
            setArtikel([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setSelectedArtikel(warengruppen.map(wg => ({id: wg.warengruppennummer, artikel: []})));
    }, [warengruppen]);


    const handleSectionCountChange = (warengruppeId, count) => {
        setSectionCounts(prev => ({...prev, [warengruppeId]: count}));
    };

    const handleDrop = (warengruppeId, artikelData) => {
        setSelectedArtikel(prevSelected =>
            prevSelected.map(wg =>
                wg.id === warengruppeId
                    ? {...wg, artikel: [...wg.artikel, artikelData]}
                    : wg,
            ),
        );
    };

    const handleSave = async () => {
        console.log("saving")
        setSavingData(true);
        if (!startDate || !endDate) {
            console.error("Startdatum oder Enddatum ist nicht gesetzt.");
            return;
        }
        console.log('Selected Artikel:', selectedArtikel);
        
        const sortimentsPlanData = {
            start_datum: startDate.format('YYYY-MM-DD'),
            end_datum: endDate.format('YYYY-MM-DD'),
            global_plan: true,
            filialnummer: null,
            warengruppen_plans: warengruppen.map(wg => {
                const selected = selectedArtikel.find(sa => sa.id === wg.warengruppennummer);
                
                return {
                    warengruppennummer: wg.warengruppennummer,
                    slot_count: sectionCounts[wg.warengruppennummer] || 3,
                    artikel: selected?.artikel.map(artikelWrapper => {
                        const artikelData = artikelWrapper.artikelData;  
                        return {
                            artikelnummer: artikelData?.artikelnummer,  
                            angebot: false
                        };
                    }) || []
                };
            })
        };

        console.log("Sortiments Plan Data:", sortimentsPlanData);

        try {
            // Sende den POST-Request zum Speichern der Daten
            const response = await axiosInstance.post('/theke/sortiment-plans/', sortimentsPlanData);

            if (response.status === 201) {
                console.log('Speichern erfolgreich');
            } else {
                console.error('Fehler beim Speichern:', response);
            }
        } catch (error) {
            if (error.response) {
                // Server hat eine Antwort zurückgegeben
                console.error('Fehler beim Speichern der Daten:', error.response.data);
            } else if (error.request) {
                // Anfrage wurde gesendet, aber keine Antwort erhalten
                console.error('Keine Antwort vom Server erhalten:', error.request);
            } else {
                // Ein anderer Fehler ist aufgetreten
                console.error('Fehler beim Konfigurieren der Anfrage:', error.message);
            }
        } finally {
            setSavingData(false);
        }
    };


    return (
        <Layout>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <PageHeader
                    title="Sortimentsplanung :)"
                    subtitle="Dieses Modul unterstützt dich bei der Sortimentsplanung."
                    Icon={StorefrontOutlinedIcon}
                />
                <Box sx={{textAlign: 'right'}}>
                    <Typography variant="h6">Zeitraum:</Typography>
                    {startDate && endDate ? (
                        <Typography variant="body1">
                            Startdatum: {startDate.format('DD.MM.YYYY')} - Enddatum: {endDate.format('DD.MM.YYYY')}
                        </Typography>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => window.location.href = '/sortiment/kalender'}
                        >
                            Zeitraum auswählen
                        </Button>
                    )}
                </Box>
            </Box>

            <DndProvider backend={HTML5Backend}>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <ThekeHeader
                        warengruppen={warengruppen}
                        setFilter={setFilter}
                        filter={filter}
                        setShowOverview={setShowOverview}
                        onOpenSettings={() => setSettingsOpen(true)}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={savingData} // Disable button if saving data is true
                    >
                        Speichern
                    </Button>

                    <Box sx={{display: 'flex', width: '100%'}}>
                        {showOverview ? (
                            <ThekeOverview
                                warengruppen={warengruppen}
                                sectionCounts={sectionCounts}
                                handleDrop={handleDrop}
                                selectedArtikel={selectedArtikel}
                            />
                        ) : (
                            <>
                                <Box sx={{width: '25%'}}>
                                    <ArtikelListe
                                        artikel={artikel.filter(artikel => artikel.warengruppennummer === filter)}
                                    />
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', width: '75%'}}>
                                    {warengruppen
                                        .filter(warengruppe => warengruppe.warengruppennummer === filter)
                                        .map(warengruppe => (
                                            <ThekeGroup
                                                key={warengruppe.warengruppennummer}
                                                title={warengruppe.warengruppe}
                                                sectionCount={sectionCounts[warengruppe.warengruppennummer] || 3}
                                                onDrop={artikelData => handleDrop(warengruppe.warengruppennummer, artikelData)}
                                                selectedArtikel={selectedArtikel.find(
                                                    wg => wg.id === warengruppe.warengruppennummer,
                                                )?.artikel || []}
                                            />
                                        ))}
                                </Box>
                            </>
                        )}
                    </Box>
                    <ThekeSettingsPopup
                        open={settingsOpen}
                        onClose={() => setSettingsOpen(false)}
                        warengruppen={warengruppen}
                        sectionCounts={sectionCounts}
                        onSectionCountChange={handleSectionCountChange}
                    />
                </Box>
            </DndProvider>
        </Layout>
    );
};

export default ThekeNewPage;
