import React, { useState, useMemo } from 'react';
import {
  TextField,
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Paper,
} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useTheme } from '@mui/material/styles';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import GridOnIcon from '@mui/icons-material/GridOn';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const VariableTable = ({ columns, data, onRowClick }) => {
  const theme = useTheme();

  const [searchTerm, setSearchTerm] = useState('');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(75);

  const filteredData = useMemo(() => {
    return data.filter((row) =>
      columns.some((column) =>
        String(row[column.accessorKey])
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [data, columns, searchTerm]);

  const sortedData = useMemo(() => {
    if (orderBy) {
      return [...filteredData].sort((a, b) => {
        let aValue = a[orderBy];
        let bValue = b[orderBy];

        // Überprüfen, ob die Werte Zahlen (mit Tausendertrennzeichen) sind
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          // Entferne Tausendertrennzeichen und ersetze Komma mit Punkt für Nachkommastellen
          const parsedA = parseFloat(aValue.replace(/\./g, '').replace(',', '.'));
          const parsedB = parseFloat(bValue.replace(/\./g, '').replace(',', '.'));

          // Wenn beide Werte gültige Zahlen sind, sortiere sie als Zahlen
          if (!isNaN(parsedA) && !isNaN(parsedB)) {
            aValue = parsedA;
            bValue = parsedB;
          }
        }

        if (aValue === bValue) return 0;
        if (order === 'asc') {
          return aValue < bValue ? -1 : 1;
        } else {
          return aValue > bValue ? -1 : 1;
        }
      });
    } else {
      return filteredData;
    }
  }, [filteredData, orderBy, order]);

  const paginatedData = useMemo(() => {
    const start = pageIndex * pageSize;
    return sortedData.slice(start, start + pageSize);
  }, [sortedData, pageIndex, pageSize]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPageIndex(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageIndex(0);
  };

  const toggleFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  // Export to CSV
  const handleExportCSV = () => {
    const csvContent = [
      columns.map((col) => col.header).join(','), // Header row
      ...filteredData.map((row) =>
        columns.map((col) => row[col.accessorKey]).join(',')
      ),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'table_data.csv');
  };

  // Export to Excel
  const handleExportExcel = () => {
    // Konvertiere die Daten in ein Arbeitsblatt
    const worksheetData = filteredData.map((row) =>
      columns.reduce((acc, column) => {
        acc[column.header] = row[column.accessorKey];
        return acc;
      }, {})
    );

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);

    // Erstelle eine neue Arbeitsmappe und füge das Arbeitsblatt hinzu
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Daten');

    // Exportiere die Arbeitsmappe als Excel-Datei
    XLSX.writeFile(workbook, 'tabelle_daten.xlsx');
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Export to PDF
  const handleExportPDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns.map((col) => col.header);
    const tableRows = filteredData.map((row) =>
      columns.map((col) => row[col.accessorKey])
    );

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      styles: { fontSize: 8 },
      headStyles: { fillColor: [22, 160, 133] },
    });

    doc.save('table_data.pdf');
  };

  const renderTable = () => (
    <>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 500,
          padding: 0, // Padding entfernen
        }}
      >
        <Table stickyHeader size="small" sx={{ borderCollapse: 'collapse' }}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column.accessorKey}
                  sortDirection={orderBy === column.accessorKey ? order : false}
                  sx={{
                    fontSize: {
                      xs: '0.6rem',
                      sm: '0.8rem',
                      md: '1rem',
                    },
                    textAlign: index === 0 ? 'left' : 'right',
                    position: index === 0 ? 'sticky' : 'static',
                    left: index === 0 ? 0 : 'auto',
                    zIndex: index === 0 ? 3 : 1,
                    backgroundColor: 'white',
                    minWidth: index === 0 ? '120px' : 'auto', // Feste Breite für die erste Spalte
                    padding: '8px', // Einheitliches Padding
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.accessorKey}
                    direction={orderBy === column.accessorKey ? order : 'asc'}
                    onClick={() => handleRequestSort(column.accessorKey)}
                  >
                    {column.header}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {paginatedData.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                hover
                onClick={() => onRowClick(row)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
              >
                {columns.map((column, colIndex) => (
                  <TableCell
                    key={column.accessorKey}
                    sx={{
                      fontSize: {
                        xs: '0.6rem',
                        sm: '0.8rem',
                        md: '1rem',
                      },
                      textAlign: colIndex === 0 ? 'left' : 'right',
                      position: colIndex === 0 ? 'sticky' : 'static',
                      left: colIndex === 0 ? 0 : 'auto',
                      zIndex: colIndex === 0 ? 2 : 1,
                      backgroundColor: 'white',
                      minWidth: colIndex === 0 ? '120px' : 'auto',
                      padding: '8px',
                    }}
                  >
                    {row[column.accessorKey]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      {filteredData.length > pageSize && (
        <TablePagination
          component="div"
          count={filteredData.length}
          page={pageIndex}
          onPageChange={handleChangePage}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[25, 50, 75, 100]}
          labelRowsPerPage="Zeilen pro Seite"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} von ${count !== -1 ? count : `mehr als ${to}`}`
          }
          nextIconButtonText="Nächste Seite"
          backIconButtonText="Vorherige Seite"
        />
      )}
    </>
  );

  return (
    <div className="db_styled_table">
      <Box mb={0.1}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={0.1}
        >
          <Box sx={{ width: '200px' }}>
            <TextField
              fullWidth
              label="Suchen"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
              sx={{
                '& .MuiInputBase-root': {
                  height: '30px',
                },
                '& .MuiInputLabel-root': {
                  fontSize: '0.875rem',
                },
              }}
            />
          </Box>

          {/* Export Buttons */}
          <Box display="flex" alignItems="center">
            <IconButton onClick={handleMenuOpen}>
              <MoreVertIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => { handleExportCSV(); handleMenuClose(); }}>
                <DownloadIcon sx={{ marginRight: 1 }} /> CSV exportieren
              </MenuItem>
              <MenuItem onClick={() => { handleExportPDF(); handleMenuClose(); }}>
                <PictureAsPdfIcon sx={{ marginRight: 1 }} /> PDF exportieren
              </MenuItem>
              <MenuItem onClick={() => { handleExportExcel(); handleMenuClose(); }}>
                <GridOnIcon sx={{ marginRight: 1 }} /> Excel exportieren
              </MenuItem>
            </Menu>

            <IconButton onClick={toggleFullscreen}>
              <FullscreenIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {/* Table */}
      {renderTable()}

      {/* Fullscreen Dialog */}
      <Dialog
        open={isFullscreen}
        onClose={toggleFullscreen}
        maxWidth={false} // Kein Limit für die maximale Breite
        fullScreen // Dialog wird den gesamten Bildschirm einnehmen
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={toggleFullscreen}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div className="db_styled_table">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Box sx={{ width: '200px' }}>
              <TextField
                fullWidth
                label="Suchen"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                size="small"
              />
            </Box>

            {/* Export Buttons in Fullscreen */}
            <Box display="flex" alignItems="center">
              <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>

              <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={() => { handleExportCSV(); handleMenuClose(); }}>
                  <DownloadIcon sx={{ marginRight: 1 }} /> CSV exportieren
                </MenuItem>
                <MenuItem onClick={() => { handleExportPDF(); handleMenuClose(); }}>
                  <PictureAsPdfIcon sx={{ marginRight: 1 }} /> PDF exportieren
                </MenuItem>
                <MenuItem onClick={() => { handleExportExcel(); handleMenuClose(); }}>
                  <GridOnIcon sx={{ marginRight: 1 }} /> Excel exportieren
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          {renderTable()}
        </div>
      </Dialog>
    </div>
  );
};

export default VariableTable;