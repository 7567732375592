import * as React from 'react';
import { Card, Typography, Stack, Box, CardActionArea } from '@mui/material';
import Sparkline from '../../charts/Sparkline2';
import EuroIcon from '@mui/icons-material/Euro';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import TimerIcon from '@mui/icons-material/Timer';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

const getCategoryIcon = (category) => {
    const iconProps = { sx: { fontSize: '2.0rem', color: 'primary.main', mr: 1 } };

    switch (category) {
        case 'Umsatz':
            return <EuroIcon {...iconProps} />;
        case 'Kundenanzahl':
            return <ShoppingCartIcon {...iconProps} />;
        case 'Kundenbon':
            return <AddShoppingCartIcon {...iconProps} />;
        case 'Leistung':
            return <TimerIcon {...iconProps} />;
        case 'Krankenquote':
            return <VaccinesIcon {...iconProps} />;
        case 'Fluktuation':
            return <GroupRemoveIcon {...iconProps} />;
        case 'Bedarf':
            return <ManageSearchIcon {...iconProps} />;
        case 'Auslastung':
            return <Battery3BarIcon {...iconProps} />;
        default:
            return null;
    }
};

export default function DataCard({ chartData, category, sector ,value, subHeaders = [], numDataPoints = 7 }) {
    const theme = useTheme(); // Access the theme
    const navigate = useNavigate(); // For navigation

    const handleNavigate = () => {
        navigate(`/unternehmen/${sector}/${category.toLowerCase()}`); // Adjust the path as necessary
    };

    return (
        <Card sx={{
            padding: theme.spacing(2), // Increased padding for better spacing
            backgroundColor: 'rgba(255, 255, 255, 0.7)', // Slightly transparent white background
            backdropFilter: 'blur(10px)', // Optional blur effect
            display: 'flex', // Ensures that the content remains in a row layout
            alignItems: 'center', // Vertically align content
        }}>
            <CardActionArea sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={handleNavigate}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    <Box>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {getCategoryIcon(category)}
                            <Typography
                                variant="h6"
                                className="db_datacard_category"
                                sx={{
                                    fontWeight: theme.typography.h5.fontWeight, // Use theme font weight
                                    fontSize: theme.typography.h6.fontSize, // Use theme font size
                                    color: theme.palette.primary.main, // Use theme text color
                                }}
                            >
                                {category}
                            </Typography>
                        </Stack>
                        <Typography
                            variant="h6"
                            className="db_datacard_value"
                            sx={{
                                fontWeight: theme.typography.h4.fontWeight, // Use theme font weight
                                fontSize: theme.typography.h4.fontSize, // Use theme font size
                                color: theme.palette.primary.main, // Use theme success color
                                mt: theme.spacing(1), // Add a slight margin-top
                            }}
                        >
                            {value}
                        </Typography>
                    </Box>

                    {/* Render each subHeader as a separate line */}
                    {subHeaders.map((subHeader, index) => (
                        <Typography
                            key={index}
                            variant="body2"
                            className="db_datacard_subheader"
                            sx={{
                                fontSize: theme.typography.body2.fontSize, // Use theme font size
                                color: theme.palette.text.secondary, // Use theme secondary text color
                                mt: index === 0 ? theme.spacing(2) : theme.spacing(1), // Add margin only to the first subheader
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Box sx={{ mr: 1 }}>
                                {subHeader.startsWith('-') ? (
                                    <Box sx={{ color: theme.palette.error.main }}>↓</Box>
                                ) : (
                                    <Box sx={{ color: theme.palette.success.main }}>↑</Box>
                                )}
                            </Box>
                            {subHeader}
                        </Typography>
                    ))}
                </Box>

                {/* Sparkline Chart */}
                <Box sx={{ width: '40%', flexShrink: 0 }}> {/* Make sure the chart stays on the right */}
                    <Sparkline data={chartData} numDataPoints={numDataPoints} />
                </Box>
            </CardActionArea>
        </Card>
    );
}
