import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Grid, Stack, Paper, Card, CardHeader, Typography, CardContent } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonatPage';
import VariableTableTree from '../../../components/table/VariableTableTree';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import { calculatePerformanceData } from '../../../utils/calculationPerformance';
import BigChartCard from "../../../components/card/scorecardsmonat/BigChartCardUnternehmen";
import BigPieChartCardTotal from '../../../components/card/scorecardsmonat/BigPieChartCardTotal';

const Leistung = () => {
    const { filterState } = useContext(FilterContext);
    const [allFilialenData, setAllFilialenData] = useState([]);
    const [filialtypData, setFilialtypData] = useState([]);

    const [performanceData, setPerformanceData] = useState({
        umsatz: 0,
        umsatzVJ: 0,
        umsatzAbweichungVJ: 0,
        kundenanzahl: 0,
        kundenanzahlVJ: 0,
        kundenanzahlAbweichungVJ: 0,
        produktivstunden: 0,
        produktivstundenVJ: 0,
        produktivstundenAbweichungVJ: 0,
        leistung: 0,
        leistungVJ: 0,
        leistungAbweichungVJ: 0,
        kundenbon: 0,
        kundenbonVJ: 0,
        kundenbonAbweichungVJ: 0,
        chartData: [],
        verkaufsgebieteResult: [],
        filialenMitLeistung: 0,
        filialtypenResult: [],
        durchschnittLeistung: 0,
    });
    const [tableData, setTableData] = useState([]);
    const [performanceLastMonths, setPerformanceLastMonths] = useState([]);

    useEffect(() => {
        if (filterState.datum) {
            fetchPerformanceData();
            fetchPerformanceDataLastMonths();
        }
    }, [filterState]);

    const formatNumberWithCommas = (number) => {
        if (typeof number !== 'number' || isNaN(number)) {
            return '0.00';
        }
        return number.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    const formatPercentage = (value) => {
        return `${value.toFixed(2)}%`;
    };

    const fetchScoreLeistungData = async () => {
        try {
            const response = await axiosInstance.get('/scorecards_month/performance-kennzahl/', {
                params: {
                    jahrMonat: filterState.datum?.value,
                },
            });
            return response.data.results;
        } catch (error) {
            console.error('Error fetching scoreLeistung data:', error);
            return [];
        }
    };

    const fetchPerformanceData = async () => {
        try {
            // Abrufen der Daten von der API
            const response = await axiosInstance.get('/scorecards_month/performance-kennzahl-gesamt/', {
                params: {
                    jahrMonat: filterState.datum?.value,
                },
            });

            // Abrufen der scoreLeistung-Daten
            const scoreLeistungData = await fetchScoreLeistungData();

            // Setzen der gesamten Filialdaten in den State
            setAllFilialenData(response.data.results);

            // Berechnungen durchführen
            const calculatedData = calculatePerformanceData(response.data.results);

            // Anzahl der Filialen mit Leistung > 0 berechnen
            const filialenMitLeistung = response.data.results.filter((filiale) => filiale.leistung > 0).length;

            // Durchschnittliche Leistung berechnen
            const durchschnittLeistung = filialenMitLeistung > 0 ? calculatedData.leistung : 0;

            // Setzen der Performance-Daten
            setPerformanceData({
                umsatz: calculatedData.umsatzSumme,
                umsatzVJ: calculatedData.umsatzVJSumme,
                umsatzAbweichungVJ: calculatedData.umsatzAbweichungVJ,
                kundenanzahl: calculatedData.kundenanzahlSumme,
                kundenanzahlVJ: calculatedData.kundenanzahlVJSumme,
                kundenanzahlAbweichungVJ: calculatedData.kundenanzahlAbweichungVJ,
                produktivstunden: calculatedData.produktivstundenSumme,
                produktivstundenVJ: calculatedData.produktivstundenVJSumme,
                produktivstundenAbweichungVJ: calculatedData.produktivstundenAbweichungVJ,
                leistung: calculatedData.leistung,
                leistungVJ: calculatedData.leistungVJ,
                leistungAbweichungVJ: ((calculatedData.leistung - calculatedData.leistungVJ) / calculatedData.leistungVJ) * 100,
                kundenbon: calculatedData.kundenbon,
                kundenbonVJ: calculatedData.kundenbonVJ,
                kundenbonAbweichungVJ: calculatedData.kundenbonAbweichungVJ,
                chartData: response.data.results.map((item) => ({
                    leistung: item.leistung || 0,
                })),
                verkaufsgebieteResult: calculatedData.verkaufsgebieteResult,
                filialenMitLeistung,
                durchschnittLeistung,
                filialtypenResult: calculatedData.filialtypenResult,
            });

            // Tabelle formatieren
            const formattedTableData = response.data.results
            .filter(filiale => filiale.leistung > 0)
            .map((filiale) => {
                const scoreData = scoreLeistungData.find(item => item.filialnummer === filiale.filialnummer) || {};
                return {
                    filialnummer: filiale.filialnummer,
                    filiale: filiale.filiale,
                    verkaufsgebiet: filiale.verkaufsgebiet,
                    filialtyp: filiale.filialtyp,
                    leistung: filiale.leistung || 0,
                    leistungVJ: filiale.leistungVJ || 0,
                    leistungAbweichungVJ: filiale.leistungVJ > 0 ? ((filiale.leistung - filiale.leistungVJ) / filiale.leistungVJ) * 100 : 0,
                    scoreLeistung: scoreData.scoreLeistung || 0, // Hier wird scoreKundenbon hinzugefügt

                };
            });

            setTableData(formattedTableData);

        } catch (error) {
            console.error('Error fetching performance data:', error);
        }
    };

    const getLastMonths = (currentMonth) => {
        const lastMonths = [];
        const formattedMonth = dayjs(currentMonth, 'YYYY.MM');
        for (let i = 0; i < 6; i++) {
            lastMonths.push(formattedMonth.subtract(i, 'month').format('YYYY.MM'));
        }
        return lastMonths.reverse();
    };

    const fetchPerformanceDataLastMonths = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');
        const lastMonths = getLastMonths(currentMonth);

        try {
            const responses = await Promise.all(
                lastMonths.map(month => axiosInstance.get('/scorecards_month/performance-kennzahl-gesamt/', {
                    params: { jahrMonat: month },
                }))
            );

            const lastMonthsData = responses.map((response, index) => {
                const produktivstundenSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.produktivstunden || 0), 0);

                const umsatzSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.umsatz || 0), 0);

                const leistung = produktivstundenSumme > 0 ? umsatzSumme / produktivstundenSumme : 0;

                const produktivstundenVJSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.produktivstundenVJ || 0), 0);

                const umsatzVJSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.umsatzVJ || 0), 0);

                const leistungVJ = produktivstundenVJSumme > 0 ? umsatzVJSumme / produktivstundenVJSumme : 0;

                // Kundenanzahl und Kundenbon für die DataCards
                const kundenanzahlSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.kundenanzahl || 0), 0);

                const kundenanzahlVJSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.kundenanzahlVJ || 0), 0);

                const kundenbon = kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;
                const kundenbonVJ = kundenanzahlVJSumme > 0 ? umsatzVJSumme / kundenanzahlVJSumme : 0;

                return {
                    month: lastMonths[index],
                    leistung: leistung,
                    leistungVJ: leistungVJ,
                    umsatz: umsatzSumme,
                    umsatzVJ: umsatzVJSumme,
                    produktivstunden: produktivstundenSumme,
                    produktivstundenVJ: produktivstundenVJSumme,
                    kundenanzahl: kundenanzahlSumme,
                    kundenanzahlVJ: kundenanzahlVJSumme,
                    kundenbon: kundenbon,
                    kundenbonVJ: kundenbonVJ,
                };
            });

            setPerformanceLastMonths(lastMonthsData);
        } catch (error) {
            console.error('Error fetching data for last months:', error);
        }
    };

    // Daten für das Leistung-Diagramm
    const chartDataLeistung = performanceLastMonths.flatMap((monthData) => [
        { x: monthData.month, y: monthData.leistung, label: 'aktuelles Jahr' },
        { x: monthData.month, y: monthData.leistungVJ, label: 'Vorjahr' }
    ]);

    // Daten für das Umsatz und Produktivstunden Diagramm
    const chartDataUmsatzProduktivstunden = performanceLastMonths.flatMap((monthData) => [
        { x: monthData.month, y: monthData.umsatz, label: 'Umsatz' },
        { x: monthData.month, y: monthData.produktivstunden, label: 'Produktivstunden' },
    ]);

    const columns = useMemo(() => [
        {
            accessorKey: 'verkaufsgebiet',
            header: 'Verkaufsgebiet',
            enableGrouping: true,
            isVisible: true,
        },
        {
            accessorKey: 'filiale',
            header: 'Filiale',
            enableGrouping: false,
            isVisible: true,
        },

        {
            accessorKey: 'scoreLeistung',
            header: 'Score Leistung',
            enableGrouping: false,
            aggregationFn: 'sum',
            Cell: ({ cell }) => formatNumberWithCommas(cell.getValue()),
            AggregatedCell: ({ cell, row }) => {
                const subRows = row.subRows || [];
                const validSubRows = subRows.filter(subRow => subRow.getValue('scoreLeistung') > 0);
                const totalScoreLeistung = validSubRows.reduce((total, subRow) => total + (subRow.getValue('scoreLeistung') || 0), 0);
                const averageScoreLeistung = validSubRows.length > 0 ? totalScoreLeistung / validSubRows.length : 0;

                return (
                    <span>{formatNumberWithCommas(averageScoreLeistung.toFixed(2))}</span>
                );
            },
            isVisible: true,
        },
        {
            accessorKey: 'leistung',
            header: 'Leistung',
            enableGrouping: false,
            aggregationFn: 'mean',
            Cell: ({ cell }) => formatNumberWithCommas(cell.getValue()),
            AggregatedCell: ({ cell }) => {
                const value = cell.getValue();
                return (
                    <span>{formatNumberWithCommas(value)}</span>
                );
            },
            isVisible: true,
        },
        {
            accessorKey: 'leistungVJ',
            header: 'Leistung VJ',
            enableGrouping: false,
            aggregationFn: 'mean',
            Cell: ({ cell }) => formatNumberWithCommas(cell.getValue()),
            AggregatedCell: ({ cell }) => {
                const value = cell.getValue();
                return (
                    <span>{formatNumberWithCommas(value)}</span>
                );
            },
            isVisible: true,
        },
        {
            accessorKey: 'leistungAbweichungVJ',
            header: 'Leistung Abw. VJ',
            enableGrouping: false,
            Cell: ({ cell }) => (
                <span>{cell.getValue().toFixed(2)}%</span>
            ),
            AggregatedCell: ({ cell, row }) => {
                const subRows = row.subRows || [];
                const aggregatedLeistung = subRows.reduce((total, subRow) => total + subRow.getValue('leistung'), 0);
                const aggregatedLeistungVJ = subRows.reduce((total, subRow) => total + subRow.original.leistungVJ, 0);

                const leistungAbweichungVJ = aggregatedLeistungVJ > 0 ? ((aggregatedLeistung - aggregatedLeistungVJ) / aggregatedLeistungVJ) * 100 : 0;

                return (
                    <span>
                        {leistungAbweichungVJ.toFixed(2)}%
                    </span>
                );
            },
            isVisible: true,
        },
    ], [performanceData]);

    // Berechnung von filialtypData bei Änderungen von allFilialenData
    useEffect(() => {
        if (allFilialenData.length > 0) {
            // Daten vorbereiten
            const filialtypData = allFilialenData.reduce((acc, filiale) => {
                const existing = acc.find(item => item.filialtyp === filiale.filialtyp);
                if (existing) {
                    existing.totalUmsatz += filiale.umsatz || 0;
                    existing.totalProduktivstunden += filiale.produktivstunden || 0;
                } else {
                    acc.push({
                        filialtyp: filiale.filialtyp,
                        totalUmsatz: filiale.umsatz || 0,
                        totalProduktivstunden: filiale.produktivstunden || 0,
                    });
                }
                return acc;
            }, []);
    
            // Leistung für jeden Filialtyp berechnen
            filialtypData.forEach(item => {
                item.leistung = item.totalProduktivstunden > 0 ? item.totalUmsatz / item.totalProduktivstunden : 0;
            });
    
            // Debugging: Überprüfen Sie die Daten
            console.log('filialtypData:', filialtypData);
    
            setFilialtypData(filialtypData);
        }
    }, [allFilialenData]);
    

    return (
        <Layout>
            <Grid container justifyContent="flex-end" alignItems="stretch">
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="stretch" spacing={2} sx={{ overflowX: 'auto' }}>
                        {/* DataCards */}
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Umsatz"
                                sector="performance"
                                value={performanceData.umsatz ? formatNumberWithCommas(performanceData.umsatz) : '0.00'}
                                subHeaders={[
                                    `${performanceData.umsatzAbweichungVJ.toFixed(2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLastMonths.map(item => item.umsatz)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Kundenanzahl"
                                sector="performance"
                                value={performanceData.kundenanzahl ? formatNumberWithCommas(performanceData.kundenanzahl) : '0'}
                                subHeaders={[
                                    `${performanceData.kundenanzahlAbweichungVJ ? performanceData.kundenanzahlAbweichungVJ.toFixed(2) : '0.00'}% zum Vorjahr`
                                ]}
                                chartData={performanceLastMonths.map(item => item.kundenanzahl)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Kundenbon"
                                sector="performance"
                                value={performanceData.kundenbon ? formatNumberWithCommas(performanceData.kundenbon) : '0.00'}
                                subHeaders={[
                                    `${performanceData.kundenbonAbweichungVJ ? performanceData.kundenbonAbweichungVJ.toFixed(2) : '0.00'}% zum Vorjahr`
                                ]}
                                chartData={performanceLastMonths.map(item => item.kundenbon)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} className="db_datacard_active">
                            <DataCard
                                category="Leistung"
                                sector="performance"
                                value={performanceData.leistung ? formatNumberWithCommas(performanceData.leistung) : '0.00'}
                                subHeaders={[
                                    `${performanceData.leistungAbweichungVJ ? performanceData.leistungAbweichungVJ.toFixed(2) : '0.00'}% zum Vorjahr`
                                ]}
                                chartData={performanceLastMonths.map(item => item.leistung)}
                            />
                        </Grid>
                    </Stack>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Paper className="db_datacard_active_content">
                        <Grid container spacing={2}>
                            {/* Linke Seite */}
                            <Grid item xs={12} lg={6}>
                                <Stack direction="column" alignItems="stretch" spacing={2} sx={{ overflowX: 'auto' }}>
                                    {/* Umsatz und Produktivstunden Chart */}
                                    <BigChartCard
                                        data={chartDataUmsatzProduktivstunden}
                                        title="Umsatz und Produktivstunden der letzten Monate"
                                        height={300}
                                    />

                                    {/* PieChart für Filialtypen */}
                                    {filialtypData && filialtypData.length > 0 && (
                                        <BigPieChartCardTotal
                                            data={filialtypData}
                                            title="Leistung nach Filialtyp"
                                            height={350}
                                            metric="leistung"  // Verwenden Sie das Metric "leistung"
                                        />
                                    )}
                                </Stack>
                            </Grid>

                            {/* Rechte Seite */}
                            <Grid item xs={12} lg={6}>
                                {/* Leistung Chart */}
                                <BigChartCard
                                    data={chartDataLeistung}
                                    title="Leistung und Leistung Vorjahr der letzten Monate"
                                    height={700}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} mt={3} sx={{ mt: 2 }}>
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    padding: '0px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                    backdropFilter: 'blur(10px)',
                                }}
                            >
                                <CardHeader
                                    title={
                                        <Typography variant="h6" component="div">
                                            Leistung
                                        </Typography>
                                    }
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <VariableTableTree
                                        columns={columns}
                                        data={tableData}
                                        initialGrouping={['verkaufsgebiet']}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default Leistung;
