import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Stepper, Step, StepLabel, List, ListItem, ListItemText, IconButton, Collapse, Alert, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

const FilialcheckHinzufuegen = ({ open, handleClose }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [checkId, setCheckId] = useState(null);
    const [categories, setCategories] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const navigate = useNavigate();

    // Zustände aus FilialcheckKonfiguration
    const [checkpointName, setCheckpointName] = useState('');
    const [checkpoints, setCheckpoints] = useState([]);
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
    const [openCheckpointDialog, setOpenCheckpointDialog] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);
    const [editingCheckpoint, setEditingCheckpoint] = useState(null);

    const steps = ['Name und Beschreibung', 'Kategorien hinzufügen', 'Check-Konfiguration'];

    const handleNext = async () => {
        if (activeStep === 0) {
            if (checkId === null) {
                // Nur dann POST-Request senden, wenn kein checkId vorhanden ist
                try {
                    const response = await axiosInstance.post('/filialcheck/checks/', {
                        name,
                        description,
                        is_active: isActive
                    });
                    setCheckId(response.data.id);
                } catch (error) {
                    console.error('Fehler beim Hinzufügen des Filialchecks:', error);
                    return;
                }
            } else {
                // Falls checkId vorhanden ist, PATCH-Request senden, um Änderungen zu speichern
                try {
                    await axiosInstance.patch(`/filialcheck/checks/${checkId}/`, {
                        name,
                        description,
                        is_active: isActive
                    });
                } catch (error) {
                    console.error('Fehler beim Aktualisieren des Filialchecks:', error);
                    return;
                }
            }
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSaveAndClose = () => {
        resetForm(); // Setze den Zustand zurück
        handleClose();
        navigate('/filialcheck/settings/index');
    };

    const handleAddCategory = async () => {
        if (categoryName.trim() === '') {
            return;
        }

        try {
            const response = await axiosInstance.post('/filialcheck/categories/', {
                name: categoryName,
                check_related: checkId,
            });
            setCategories([...categories, response.data]);
            setCategoryName('');
        } catch (error) {
            console.error('Fehler beim Hinzufügen der Kategorie:', error);
        }
    };

    const handleDeleteCategory = async (categoryId) => {
        try {
            await axiosInstance.delete(`/filialcheck/categories/${categoryId}/`);
            setCategories(categories.filter((category) => category.id !== categoryId));
        } catch (error) {
            console.error('Fehler beim Löschen der Kategorie:', error);
        }
    };

    // Funktionen aus FilialcheckKonfiguration

    useEffect(() => {
        if (activeStep === 2 && checkId) {
            // Kategorien für den Check abrufen
            axiosInstance.get('/filialcheck/categories/')
                .then(response => {
                    const filteredCategories = response.data.results.filter(category => category.check_related === checkId);
                    setCategories(filteredCategories);
                })
                .catch(error => {
                    console.error('Fehler beim Abrufen der Kategorien:', error);
                });

            // Checkpoints abrufen
            axiosInstance.get('/filialcheck/checkpoints/')
                .then(response => {
                    setCheckpoints(response.data.results);
                })
                .catch(error => {
                    console.error('Fehler beim Abrufen der Checkpoints:', error);
                });
        }
    }, [activeStep, checkId]);

    const handleSaveCategoryInStep3 = () => {
        if (categoryName.trim() === '') {
            setError('Kategoriename darf nicht leer sein.');
            return;
        }

        if (editingCategory) {
            // Bearbeitete Kategorie speichern
            axiosInstance.patch(`/filialcheck/categories/${editingCategory.id}/`, { name: categoryName })
                .then((response) => {
                    setCategories(categories.map(category => category.id === editingCategory.id ? response.data : category));
                    setEditingCategory(null);
                    setCategoryName('');
                    setOpenCategoryDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Bearbeiten der Kategorie.');
                    console.error('Fehler beim Bearbeiten der Kategorie:', error);
                });
        } else {
            // Neue Kategorie speichern
            axiosInstance.post('/filialcheck/categories/', { name: categoryName, check_related: checkId })
                .then((response) => {
                    setCategories([...categories, response.data]);
                    setCategoryName('');
                    setError(null);
                    setOpenCategoryDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Speichern der Kategorie.');
                    console.error('Fehler beim Speichern der Kategorie:', error);
                });
        }
    };

    const handleSaveCheckpoint = () => {
        if (checkpointName.trim() === '') {
            setError('Überprüfungspunktname darf nicht leer sein.');
            return;
        }

        if (editingCheckpoint) {
            // Bearbeiteten Checkpoint speichern
            axiosInstance.patch(`/filialcheck/checkpoints/${editingCheckpoint.id}/`, { name: checkpointName })
                .then((response) => {
                    setCheckpoints(checkpoints.map(checkpoint => checkpoint.id === editingCheckpoint.id ? response.data : checkpoint));
                    setEditingCheckpoint(null);
                    setCheckpointName('');
                    setOpenCheckpointDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Bearbeiten des Überprüfungspunkts.');
                    console.error('Fehler beim Bearbeiten des Überprüfungspunkts:', error);
                });
        } else {
            // Neuen Checkpoint speichern
            axiosInstance.post('/filialcheck/checkpoints/', { name: checkpointName, category: selectedCategory })
                .then((response) => {
                    setCheckpoints([...checkpoints, response.data]);
                    setCheckpointName('');
                    setError(null);
                    setOpenCheckpointDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Speichern des Überprüfungspunkts.');
                    console.error('Fehler beim Speichern des Überprüfungspunkts:', error);
                });
        }
    };

    const handleDeleteCategoryInStep3 = (id) => {
        axiosInstance.delete(`/filialcheck/categories/${id}`)
            .then(() => {
                setCategories(categories.filter(category => category.id !== id));
            })
            .catch(error => {
                console.error('Fehler beim Löschen der Kategorie:', error);
            });
    };

    const handleDeleteCheckpoint = (id) => {
        axiosInstance.delete(`/filialcheck/checkpoints/${id}`)
            .then(() => {
                setCheckpoints(checkpoints.filter(checkpoint => checkpoint.id !== id));
            })
            .catch(error => {
                console.error('Fehler beim Löschen des Checkpoints:', error);
            });
    };

    const handleEditCategory = (category) => {
        setEditingCategory(category);
        setCategoryName(category.name);
        setOpenCategoryDialog(true);
    };

    const handleEditCheckpoint = (checkpoint) => {
        setEditingCheckpoint(checkpoint);
        setCheckpointName(checkpoint.name);
        setOpenCheckpointDialog(true);
    };

    const handleAddCheckpointPlaceholderClick = () => {
        setCheckpointName('');
        setOpenCheckpointDialog(true);
    };

    // Funktion zum Löschen des erstellten Checks und Zurücksetzen des Formulars
    const deleteCheck = async () => {
        if (checkId) {
            try {
                // Lösche alle zugehörigen Kategorien
                for (let category of categories) {
                    await axiosInstance.delete(`/filialcheck/categories/${category.id}/`);
                }
                // Lösche den Check
                await axiosInstance.delete(`/filialcheck/checks/${checkId}/`);
            } catch (error) {
                console.error('Fehler beim Löschen des Filialchecks:', error);
            }
        }
    };

    const resetForm = () => {
        setActiveStep(0);
        setName('');
        setDescription('');
        setIsActive(true);
        setCheckId(null);
        setCategories([]);
        setCategoryName('');
        setCheckpointName('');
        setCheckpoints([]);
        setError(null);
        setSelectedCategory(null);
        setOpenCategoryDialog(false);
        setOpenCheckpointDialog(false);
        setEditingCategory(null);
        setEditingCheckpoint(null);
    };

    // Funktion zum Öffnen des Bestätigungsdialogs beim Schließen
    const handleCloseWithConfirmation = () => {
        setOpenConfirmDialog(true);
    };

    // Funktion, die ausgeführt wird, wenn der Nutzer das Schließen bestätigt
    const handleConfirmClose = async () => {
        await deleteCheck(); // Lösche den angelegten Check inkl. Kategorien
        resetForm();
        setOpenConfirmDialog(false);
        handleClose();
    };

    // Funktion, die ausgeführt wird, wenn der Nutzer das Schließen abbricht
    const handleCancelClose = () => {
        setOpenConfirmDialog(false);
    };

    // Anpassung des onClose-Events, um Schließen über Klick außerhalb oder Escape-Taste abzufangen
    const handleDialogClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            handleCloseWithConfirmation();
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleDialogClose} // Anpassung des onClose-Events
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Neuen Filialcheck hinzufügen</DialogTitle>
                <DialogContent>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box sx={{ mt: 3 }}>
                        {activeStep === 0 && (
                            <Box component="form" sx={{ width: '100%' }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name des Filialchecks"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="description"
                                    label="Beschreibung"
                                    name="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    multiline
                                    rows={4}
                                />
                            </Box>
                        )}
                        {activeStep === 1 && (
                            <Box>
                                <Typography variant="h6" gutterBottom>Kategorien hinzufügen</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <TextField
                                        label="Kategoriename"
                                        value={categoryName}
                                        onChange={(e) => setCategoryName(e.target.value)}
                                        fullWidth
                                    />
                                    <IconButton color="primary" onClick={handleAddCategory}>
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                                <List>
                                    {categories.map((category) => (
                                        <React.Fragment key={category.id}>
                                            <ListItem
                                                secondaryAction={
                                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCategory(category.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                }
                                            >
                                                <ListItemText primary={category.name} />
                                            </ListItem>
                                            <Divider />
                                        </React.Fragment>
                                    ))}
                                </List>
                            </Box>
                        )}
                        {activeStep === 2 && checkId && (
                            <Box>
                                {error && (
                                    <Alert severity="error" sx={{ mb: 2 }}>
                                        {error}
                                    </Alert>
                                )}
                                <Box component="form" sx={{ mb: 2 }}>
                                    <List>
                                        {categories.length > 0 ? categories.map((category) => (
                                            <CategoryItem
                                                key={category.id}
                                                category={category}
                                                selectedCategory={selectedCategory}
                                                setSelectedCategory={setSelectedCategory}
                                                checkpoints={checkpoints}
                                                handleDeleteCategory={handleDeleteCategoryInStep3}
                                                handleEditCategory={handleEditCategory}
                                                handleDeleteCheckpoint={handleDeleteCheckpoint}
                                                handleEditCheckpoint={handleEditCheckpoint}
                                                handleAddCheckpointPlaceholderClick={handleAddCheckpointPlaceholderClick}
                                            />
                                        )) : (
                                            <Typography variant="body2" color="textSecondary">Keine Kategorien vorhanden.</Typography>
                                        )}
                                    </List>
                                    
                                    {/* Button zum Hinzufügen einer neuen Kategorie im gleichen Stil wie der Platzhalter */}
                                    <Box
                                        sx={{
                                            border: '2px dashed #ddd',
                                            borderRadius: '8px',
                                            padding: '16px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            color: '#888',
                                            mt: 2,
                                            width: '100%',
                                            '&:hover': {
                                                backgroundColor: '#f5f5f5'
                                            },
                                            boxSizing: 'border-box',
                                        }}
                                        onClick={() => setOpenCategoryDialog(true)}
                                    >
                                        <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                                        Neue Kategorie anlegen
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        variant="outlined"
                    >
                        Zurück
                    </Button>
                    <Button
                        onClick={handleCloseWithConfirmation}
                        variant="outlined"
                        color="secondary"
                    >
                        Abbrechen
                    </Button>
                    {activeStep === steps.length - 1 ? (
                        <Button
                            onClick={handleSaveAndClose}
                            variant="contained"
                            color="primary"
                        >
                            Fertigstellen
                        </Button>
                    ) : (
                        <Button
                            onClick={handleNext}
                            variant="contained"
                            color="primary"
                            disabled={activeStep === 1 && categories.length === 0}
                        >
                            Weiter
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            {/* Bestätigungsdialog */}
            <Dialog
                open={openConfirmDialog}
                onClose={handleCancelClose}
            >
                <DialogTitle>Bestätigung</DialogTitle>
                <DialogContent>
                    <Typography>
                        Möchtest du wirklich abbrechen? Alle nicht gespeicherten Änderungen gehen verloren.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelClose} color="primary">
                        Nein
                    </Button>
                    <Button onClick={handleConfirmClose} color="secondary">
                        Ja, abbrechen
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Kategoriedialog */}
            <CategoryDialog
                open={openCategoryDialog}
                onClose={() => setOpenCategoryDialog(false)}
                categoryName={categoryName}
                setCategoryName={setCategoryName}
                handleSaveCategory={handleSaveCategoryInStep3}
            />

            {/* Checkpointdialog */}
            <CheckpointDialog
                open={openCheckpointDialog}
                onClose={() => setOpenCheckpointDialog(false)}
                checkpointName={checkpointName}
                setCheckpointName={setCheckpointName}
                handleSaveCheckpoint={handleSaveCheckpoint}
            />
        </>
    );
};

// Kategorie-Item-Komponente
const CategoryItem = ({ category, selectedCategory, setSelectedCategory, checkpoints, handleDeleteCategory, handleEditCategory, handleDeleteCheckpoint, handleEditCheckpoint, handleAddCheckpointPlaceholderClick }) => (
    <Box sx={{ border: '1px solid #ddd', borderRadius: '8px', p: 2, mb: 2 }}>
        <ListItem
            secondaryAction={
                <>
                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditCategory(category)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCategory(category.id)}>
                        <DeleteIcon />
                    </IconButton>
                </>
            }
            onClick={() => setSelectedCategory(selectedCategory === category.id ? null : category.id)}
            selected={selectedCategory === category.id}
        >
            <ListItemText primary={category.name} />
        </ListItem>
        <Collapse in={selectedCategory === category.id} timeout="auto" unmountOnExit>
            <Box sx={{ mt: 2, mb: 2 }}>
                <CheckpointList
                    checkpoints={checkpoints.filter(checkpoint => checkpoint.category === category.id)}
                    handleDeleteCheckpoint={handleDeleteCheckpoint}
                    handleEditCheckpoint={handleEditCheckpoint}
                />
                <Box
                    sx={{
                        border: '2px dashed #ddd',
                        borderRadius: '8px',
                        padding: '16px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        color: '#888',
                        mt: 2,
                        width: '100%',
                        '&:hover': {
                            backgroundColor: '#f5f5f5'
                        },
                        boxSizing: 'border-box',
                    }}
                    onClick={handleAddCheckpointPlaceholderClick}
                >
                    <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Checkpoint anlegen
                </Box>
            </Box>
        </Collapse>
    </Box>
);

// Checkpoint-Liste-Komponente
const CheckpointList = ({ checkpoints, handleDeleteCheckpoint, handleEditCheckpoint }) => (
    <List sx={{ width: '100%' }}>
        {checkpoints.length > 0 ? (
            checkpoints.map((checkpoint) => (
                <Box
                    key={checkpoint.id}
                    sx={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        p: 2,
                        mb: 1,
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                >
                    <ListItem
                        secondaryAction={
                            <>
                                <IconButton edge="end" aria-label="edit" onClick={() => handleEditCheckpoint(checkpoint)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCheckpoint(checkpoint.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </>
                        }
                    >
                        <ListItemText primary={checkpoint.name} />
                    </ListItem>
                </Box>
            ))
        ) : (
            <Typography variant="body2" color="textSecondary">
                Keine Überprüfungspunkte vorhanden.
            </Typography>
        )}
    </List>
);

// Kategoriedialog-Komponente
const CategoryDialog = ({ open, onClose, categoryName, setCategoryName, handleSaveCategory }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{categoryName ? 'Kategorie bearbeiten' : 'Neue Kategorie anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Kategoriename"
                fullWidth
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                margin="normal"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveCategory} variant="contained" color="primary">
                Speichern
            </Button>
            <Button onClick={onClose} variant="outlined">
                Abbrechen
            </Button>
        </DialogActions>
    </Dialog>
);

// Checkpointdialog-Komponente
const CheckpointDialog = ({ open, onClose, checkpointName, setCheckpointName, handleSaveCheckpoint }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{checkpointName ? 'Überprüfungspunkt bearbeiten' : 'Neuen Überprüfungspunkt anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Überprüfungspunkt Name"
                fullWidth
                value={checkpointName}
                onChange={(e) => setCheckpointName(e.target.value)}
                margin="normal"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveCheckpoint} variant="contained" color="primary">
                Speichern
            </Button>
            <Button onClick={onClose} variant="outlined">
                Abbrechen
            </Button>
        </DialogActions>
    </Dialog>
);

export default FilialcheckHinzufuegen;
