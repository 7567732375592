import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useReducer } from "react";
import { useAuth } from "../provider/authProvider";
import { validateToken, validatePermission } from "../services/authService";
import CircularIndeterminate from "../components/utility/Loading";

const initialState = {
    isLoading: true,
    isValid: false,
    hasPermission: false,
    permissionChecked: false,
};

function reducer(state, action) {
    switch (action.type) {
        case "SET_LOADING":
            return { ...state, isLoading: true, permissionChecked: false };
        case "SET_VALID":
            return { ...state, isValid: action.payload, isLoading: false };
        case "SET_PERMISSION":
            return { ...state, hasPermission: action.payload, permissionChecked: true, isLoading: false };
        case "SET_ERROR":
            return { ...state, isValid: false, hasPermission: false, permissionChecked: true, isLoading: false };
        default:
            return state;
    }
}

export const ProtectedRoute = ({ module, submodule, children }) => {
    const { token } = useAuth();
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isLoading, isValid, hasPermission, permissionChecked } = state;

    useEffect(() => {
        const authenticate = async () => {
            dispatch({ type: "SET_LOADING" });

            try {
                if (!token) {
                    dispatch({ type: "SET_ERROR" });
                    return;
                }

                const isValidToken = await validateToken(token);

                if (!isValidToken) {
                    dispatch({ type: "SET_ERROR" });
                    return;
                }

                dispatch({ type: "SET_VALID", payload: true });
                console.log("before check")
                if (module) {
                    console.log("checking")
                    const isValidPermission = await validatePermission(module, submodule);
                    dispatch({ type: "SET_PERMISSION", payload: isValidPermission });
                    console.log("validPer", isValidPermission)
                } else {
                    console.log("not checking")
                    dispatch({ type: "SET_PERMISSION", payload: true });
                }
            } catch (error) {
                console.error("Authentication error:", error);
                dispatch({ type: "SET_ERROR" });
            }
        };

        authenticate(); // Re-run on every route change
    }, [token, module, submodule]);

    useEffect(() => {
        if (permissionChecked) {
            if (!isValid) {
                navigate("/login");
            } else if (!hasPermission) {
                navigate("/access-denied");
            }
        }
    }, [isValid, hasPermission, permissionChecked, navigate]);

    if (isLoading || !permissionChecked) {
        return <CircularIndeterminate />;
    }

    return children ? children : <Outlet />;
};

export default ProtectedRoute;
