import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import { tausenderTrennung } from '../../../utils/mathStuff';
import BigTableCard from '../../../components/card/BigTableCard';
import WareWachstumTAWarengruppePopup from '../../popup/WareWachstumTAWarengruppePopup'; // Import the new popup component

const TAWareWachstum = ({ open, handleClose, filiale, jahrMonat }) => {
    const [tableColumns, setTableColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [selectedWarengruppe, setSelectedWarengruppe] = useState(null);
    const [chartPopupOpen, setChartPopupOpen] = useState(false);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (open && filiale && jahrMonat) {
            fetchTiefenanalyseData();
        }
    }, [open, filiale, jahrMonat]);

    const fetchTiefenanalyseData = async () => {
        try {
            const endDate = dayjs(`${jahrMonat}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(5, 'month');

            const response = await axiosInstance.get('scorecards_month/produkte-warengruppe/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filiale,
                }
            });

            const data = response.data.results;

            if (!data || data.length === 0) {
                setTableColumns([]);
                setTableData([]);
                return;
            }

            // Create unique months for columns in YYYY.MM format
            const uniqueMonths = Array.from(new Set(data.map(item => dayjs(item.datum).format('YYYY.MM'))));
            const columns = [
                { id: 'warengruppe', accessorKey: 'warengruppe', header: 'Warengruppe' },
                ...uniqueMonths.map((month, index) => ({
                    id: `month-${index}`,
                    accessorKey: `month-${index}`,
                    header: month,
                })),
            ];

            // Prepare table data
            const warengruppeMap = {};
            data.forEach(item => {
                const month = dayjs(item.datum).format('YYYY.MM');

                if (!warengruppeMap[item.warengruppe]) {
                    warengruppeMap[item.warengruppe] = { warengruppe: item.warengruppe };
                }

                warengruppeMap[item.warengruppe][`month-${uniqueMonths.indexOf(month)}`] = tausenderTrennung(item.verkaufsmenge, 0);
            });

            const tableRows = Object.values(warengruppeMap);

            setTableColumns(columns);
            setTableData(tableRows);
        } catch (error) {
            console.error('Ein Fehler ist beim Abrufen der Tiefenanalyse-Daten aufgetreten:', error);
        }
    };

    // Handle row click to show the chart popup
    const handleRowClick = (row) => {
        setSelectedWarengruppe(row.warengruppe);
        // Prepare data for the chart popup
        const chartData = tableData
            .filter(item => item.warengruppe === row.warengruppe)
            .map(item => ({
                id: row.warengruppe,
                data: tableColumns.slice(1).map((col, index) => ({
                    x: col.header,
                    y: parseFloat(item[`month-${index}`].replace(/,/g, '')) || 0,
                }))
            }));
        setChartData(chartData);
        setChartPopupOpen(true);
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Tiefenanalyse</Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <BigTableCard
                    title="Verkaufsmenge für die letzten 6 Monate"
                    data={tableData}
                    columns={tableColumns}
                    onRowClick={handleRowClick} // Add the row click handler
                />
            </DialogContent>

            {/* Chart Popup */}
            <WareWachstumTAWarengruppePopup
                open={chartPopupOpen}
                handleClose={() => setChartPopupOpen(false)}
                data={chartData}
                warengruppeName={selectedWarengruppe}
            />
        </Dialog>
    );
};

export default TAWareWachstum;
