import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import Documentation from "./Documentation";
import { Fab, Box } from "@mui/material";

const ScorecardsNotes = () => {
  const [docOpen, setDocOpen] = useState(false);

  const handleFabClick = () => {
    setDocOpen(true);
  };

  const handleDocClose = () => {
    setDocOpen(false);
  };

  return (
    <Box sx={{ display: "flex", transition: "margin 0.3s ease-in-out" }}>
      {/* Main content area */}
      <Box
        sx={{
          flexGrow: 1,
          marginRight: docOpen ? "350px" : "0px", // Adjust the margin when sidebar is open
          transition: "margin 0.3s ease-in-out",
        }}
      >
        {/* Your main content goes here */}
        <Fab
  color="primary"
  aria-label="add"
  sx={{
    position: "fixed",
    bottom: 50,
    right: 50,
    width: {
      xs: 50, // Kleinere Breite auf kleinen Bildschirmen
      sm: 60, // Mittelgroße Breite auf mittleren Bildschirmen
      md: 70, // Standardbreite auf großen Bildschirmen
    },
    height: {
      xs: 50, // Kleinere Höhe auf kleinen Bildschirmen
      sm: 60, // Mittelgroße Höhe auf mittleren Bildschirmen
      md: 70, // Standardhöhe auf großen Bildschirmen
    },
    fontSize: {
      xs: "1.5rem", // Kleinere Schriftgröße auf kleinen Bildschirmen
      sm: "1.rem", // Mittelgroße Schriftgröße auf mittleren Bildschirmen
      md: "2rem", // Standardgröße auf großen Bildschirmen
    },
  }}
  onClick={handleFabClick}
>
  <AddIcon fontSize="inherit" />
</Fab>

      </Box>

      {/* Sidebar */}
      <Documentation open={docOpen} handleClose={handleDocClose} />
    </Box>
  );
};

export default ScorecardsNotes;
